import React from 'react';
import styled from 'styled-components';
import { SelectionContainer, SelectionText, StyledLink } from './styles';
import { SelectionsContainer } from 'pages/home/pages/Reservations/styles';
import ArrowSVG from 'resources/img/arrow-blue.svg';
import useStep from 'hooks/useStep';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import IconButton from 'components/Buttons/IconButton';
import useI18n from 'i18n/useI18n';
import colors from 'resources/constants/colors';
import Calendar from 'pages/home/pages/Reservations/components/CardHeader/ViewSelector/calendar';

const ShowCalendar = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
`;

const BackButton = styled(IconButton)`
  img {
    transform: rotate(180deg);
  }
  p {
    color: ${colors.accept};
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

const Selections = ({ isSummary, scheduleText, summaryText, url }) => {
  const [step, previous, next] = useStep();
  const { translate } = useI18n();
  const bodyRef = useRef(null);
  const [bodyHeight, setBodyHeight] = useState(null);
  const [firstHeightCheck, setFirstHeightCheck] = useState(true);
  const [calendarVisible, setCalendarVisible] = useState(false);

  useEffect(() => {
    if (!bodyRef?.current || !firstHeightCheck) return;
    setBodyHeight(bodyRef.current.clientHeight);
    setFirstHeightCheck(true);
  }, [bodyRef?.current]);

  useEffect(()=>{
      if(step===1){
        setTimeout(()=>{setCalendarVisible(true)}, 150);
        return;
      }
      setCalendarVisible(false);
  }, [step]);

  return (
    <SelectionsContainer ref={bodyRef} minHeight={bodyHeight ? bodyHeight : '0'} className={step === 1 ? 'expanded' : ''}>
      {step === 0 && (
        <>
          <SelectionContainer style={{ marginBottom: '10px' }} active={!isSummary}>
            <StyledLink to={`${url}/schedule`}>
              <SelectionText>{scheduleText}</SelectionText>
            </StyledLink>
            <ShowCalendar src={ArrowSVG} alt={'show-calendar'} onClick={next} />
          </SelectionContainer>
          <SelectionContainer active={isSummary}>
            <StyledLink to={`${url}/summary`}>
              <SelectionText>{summaryText}</SelectionText>
            </StyledLink>
          </SelectionContainer>
        </>
      )}
      {step === 1 && (
        <>
          <BackButton onClick={previous} icon={'arrow-left'} text={translate('Back')} />
          {calendarVisible && <Calendar full="Compact view" short="Full view" />}
        </>
      )}
    </SelectionsContainer>
  );
};

export default Selections;
