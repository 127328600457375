import styled from 'styled-components';
import colors from 'resources/constants/colors';

const DAY_SIZE = '40px';
const DAY_SIZE_SMALL = '30px';
const DAY_SIZE_SMALLEST = '20px';

const Container = styled.div`
  width: 100%;
  .react-datepicker {
    border: unset;
    width: 100%;
    font-family: 'Montserrat' !important;
    color: ${colors.textMain};

    .spinner {
      display: none;
      position: absolute;
      right: -30px;
      top: 1px;
    }

    &.loading {
      .spinner {
        display: block;
      }
    }

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__header {
        background-color: unset;
        border: unset;

        .react-datepicker__current-month {
          margin: 12px 0 24px 0;
          font-weight: 400;
          font-size: 1rem;
          display: flex;
          justify-content: center;

          .label {
            position: relative;
          }
        }

        .react-datepicker__day-names {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          .react-datepicker__day-name {
            justify-self: center;
            text-align: center;
            font-size: 0.75rem;
            width: ${DAY_SIZE};
            height: ${DAY_SIZE};

            @media only screen and (max-width: 502px) {
              font-size: 0.65rem;
              width: ${DAY_SIZE_SMALLEST};
              height: ${DAY_SIZE_SMALLEST};
            }

            @media only screen and (max-width: 1220px) and (min-width: 993px) {
              width: ${DAY_SIZE_SMALL};
              height: ${DAY_SIZE_SMALL};
            }
          }
        }
      }

      .react-datepicker__month {
        .react-datepicker__week {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          .react-datepicker__day {
            display: flex;
            justify-self: center;
            width: ${DAY_SIZE};
            height: ${DAY_SIZE};
            align-items: center;
            justify-content: center;
            font-size: 1rem;

            @media only screen and (max-width: 502px) {
              font-size: 0.75rem;
              width: ${DAY_SIZE_SMALLEST};
              height: ${DAY_SIZE_SMALLEST};
            }

            @media only screen and (max-width: 1220px) and (min-width: 993px) {
              font-size: 0.875rem;
              width: ${DAY_SIZE_SMALL};
              height: ${DAY_SIZE_SMALL};
            }

            &.react-datepicker__day--highlighted {
              color: inherit;
              background-color: ${colors.lightBlue};
              border-radius: 8px;
            }

            &.react-datepicker__day--selected {
              background-color: ${colors.accept};
              border-radius: 8px;
              font-weight: 700;
              color: white;
            }
          }
        }
        .react-datepicker__month-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px 0;
          .react-datepicker__month-text {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2rem;
            font-weight: 700;
            font-size: 1rem;
          }
        }
      }
    }

    .react-datepicker__navigation {
      border: unset;
      top: 20px;
      height: 20px;
      width: 20px;
    }

    .react-datepicker__navigation::after {
      border-style: solid;
      border-width: 1px 1px 0 0;
      content: '';
      display: inline-block;
      height: 10px;
      width: 10px;
      left: 5px;
      top: 5px;
      position: absolute;
      color: black;
      transform: rotate(-135deg);
      vertical-align: top;
    }

    .react-datepicker__navigation--next:after {
      transform: rotate(45deg);
    }
  }
`;

export default Container;
