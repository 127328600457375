import styled from 'styled-components';
import colors from 'resources/constants/colors';

export const Container = styled.div`
  display: grid;
  font-size: 0.875rem;
  margin: 8px 0px;
  align-items: center;
  grid-template-columns: 28px 1fr ${({ withAction }) => (withAction ? '80px' : '')};
  grid-gap: 15px;
  position: relative;
`;

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Action = styled.div`
  margin: 0;
  color: ${colors.accept};
  text-align: left;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
`;

export const Info = styled.div`
  ${({ color }) => (color ? `color: ${color};` : '')}
  margin: 0;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

export const InfoAlt = styled(Info)`
  max-width: 100%;
  overflow: visible;
`;

export const AdditionalInfo = styled(Info)`
  max-width: 100%;
  overflow: visible;
  position: absolute;
  bottom: -15px;
  left: calc(28px + 15px);
  font-weight: 700;
`;