import React from 'react';
import Text from 'components/DynamicTable/cells/Fixed/Text';
import { formatTime } from 'utils/time';
import useI18n from 'i18n/useI18n';


const getTimeCancel = ({ updatedDate }, {createdAt, updatedAt, expirationSeconds}, translateSeconds) => {
    const create = new Date(createdAt);
    const update = new Date(updatedAt);
    const updateOT = new Date(updatedDate);

    let diff = update.getTime() - create.getTime();
    diff = diff - (expirationSeconds * 1000) > 0 ? diff - (expirationSeconds * 1000) : diff;
    let diff2 = updateOT.getTime() - create.getTime();
    diff2 = diff2 - (expirationSeconds * 1000) > 0 ? diff2 - (expirationSeconds * 1000) : diff2;

    return diff > diff2 ? `${formatTime(diff)} ${translateSeconds(diff)}` : `${formatTime(diff2)} ${translateSeconds(diff2)}`;
}

const TimeCancel = ({ item }) => {
    const { translateSeconds } = useI18n();
  return (<Text text={item.link ? getTimeCancel(item, item.link, translateSeconds) : ''} />);
}

export default TimeCancel;
