import React from 'react';
import styled from 'styled-components';
import SearchIcon from 'resources/img/search-icon.svg';
import colors from 'resources/constants/colors';
import useI8n from "i18n/useI18n";

const InternalContainer = styled.div`
  order: 3;
  display: flex;
  background-color: transparent;
  height: 48px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
  transition: width 0.5s;
  width: 30px;
  margin-left: 15px;

  &:hover, &.active {
    width: 35%;
  }

  @media( min-width: 768px ){
    order: 3;
    margin-left: auto;

    &:hover, &.active {
      width: 230px;
    }
  }
`;

const InputAlternative = styled.input`
  flex: 1;
  font-weight: 600;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  font-size: 0.875rem;
  margin-left: 5px;
  min-width: 0;
  outline: none;
`;

const Icon = styled.img`
  width: 20px;
`;

const Search = ({ search, onChange }) => {
  const { translate } = useI8n();

  return (
    <InternalContainer className={search!=='' ? 'active' : ''}>
      <Icon src={SearchIcon} alt="search icon" />
      <InputAlternative type="text" placeholder={translate("Search")} value={search} onChange={onChange} />
    </InternalContainer>
  );
};

export default Search;
