import React from 'react';
import IconButton from './IconButton';
import colors from 'resources/constants/colors';
import styled from 'styled-components';

const Button = styled(IconButton)`
  margin-left: 0px;
  p {
    color: ${colors.accept};
    font-size: 14px;

    @media( min-width: 768px ){
      font-size: 15px;
    font-weight: 600;
    }
  }
`;

const ArrowButton = ({onClick, text}) => {
  return <Button onClick={onClick} icon={'arrow-right'} text={text} loading={false} leading={false}/>;
};

export default ArrowButton;
