import React from 'react';
import styled from 'styled-components';
import LogoutButton from './LogoutButton';
import Notifications from './Notifications';
import User from './User';
import Lang from './Lang';
import Logo from './Logo';
import Tabs from '../Tabs';
import ShowMenu from './ShowMenu'
import colors from 'resources/constants/colors';

const Container = styled.div`
  width: 100%;
  padding: 5px 2.5%;
  display: grid;
  align-items: center;
  justify-self: center;
  background-color: ${colors.base};
  grid-template-columns: 230px 2fr 1fr;

  @media( max-width: 1000px ){
    display: flex;
    padding: 5px 15px;
    justify-content: space-between;
    background-color: ${colors.backgroundLight}
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;

  @media( min-width: 768px ){
    gap: 25px;
  }
`;

const Header = ({ location, routes }) => {
  return (
    <Container>
      <Logo />
      <Tabs routes={routes} pathIndex={1} current={location.pathname} />
      <ActionsContainer>
        <Notifications />
        <Lang />
        <User />
        <ShowMenu />
        <LogoutButton />
      </ActionsContainer>
    </Container>
  );
};
export default Header;
