import React from 'react';
import { Text } from 'components/Text';
import useI18n from 'i18n/useI18n';
import { useState } from 'react';
import { useEffect } from 'react';
import ChevronIcon from 'resources/img/chevron-icon-down.svg';
import useDate from 'state/date';
import { DISPLAY_DATE_FORMAT, stringToDate } from 'utils/date';
import useReservations from 'state/reservations';
import Selections from './Selections';
import { isSummary } from '../utils';
import { Spinner } from "@etiquette-ui/misc";
import { 
  ChevronContainer, 
  Chevron, 
  Label, 
  ValueContainer,
  SelectorContainer
} from 'pages/home/pages/Reservations/styles';
import colors from 'resources/constants/colors';
import styled from 'styled-components';

const TextContainer = styled.div`
  display: flex;
  
  p {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ViewSelector = ({ pathname, url }) => {
  const { translate, formatDate } = useI18n();
  const [visible, setVisible] = useState(false);
  const [date] = useDate();
  const [reservations] = useReservations();

  useEffect(() => {
    setVisible(false);
  }, [pathname]);

  const scheduleText = () => `${formatDate(stringToDate(date), DISPLAY_DATE_FORMAT)} ${reservations.dates[date] ? `(${reservations.dates[date].length})` : ''}`;
  const summaryText = () => `${translate('Reservation Summary')}`;

  return (
    <SelectorContainer style={{ width: '350px', maxWidth: '50%', margin: '0px' }}>
      <ValueContainer onClick={() => setVisible(!visible)}>
        <Label>{translate('View by')}</Label>
        <TextContainer>
          <Text style={{ userSelect: 'none', marginRight: '5px' }}>{isSummary(pathname) ? summaryText() : scheduleText()}</Text>
          {reservations.isFetching && 
            <Spinner 
              style={{
                '--width': '15px',
                '--margin': '0 5px',
                '--bg': `${colors.backgroundLightGrey}`,
                '--color': `${colors.textMain}`
              }} 
            />
          }
        </TextContainer>
        <ChevronContainer>
          <Chevron src={ChevronIcon} alt={'select-view'} />
        </ChevronContainer>
      </ValueContainer>
      {visible && <Selections isSummary={isSummary(pathname)} url={url} scheduleText={scheduleText()} summaryText={summaryText()} />}
    </SelectorContainer>
  );
};

export default ViewSelector;
