import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { TableContext } from '../TableProvider.jsx';
import { addValueToObject } from '../objectUtils.js';

const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
    column-gap: 5px;
    min-height: 45px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
`;

const Header = ({ style = {}, applyFilter }) => {
    const { structure, gridColumns, dragable, globalState, setGlobalState, setGlobalStateBulk } = useContext(TableContext);

    const [state, replaceState] = useState({});

    const setState = (key, value) => {
        const data = { ...state };
        addValueToObject(data, key, value);
        replaceState(data);
    };

    const setStateBulk = (items) => {
        const data = { ...state };
        for (const key in items) {
            addValueToObject(data, key, items[key]);
        }
        replaceState(data);
    };

    return (
        <HeaderContainer columns={gridColumns} style={style} className="header">
            {dragable && <div></div>}
            {structure.columns.map((col, i) => {
                const Component = col.header;
                return (
                    <Component
                        key={`header_${i}`}
                        applyFilter={applyFilter}
                        state={state}
                        setState={setState}
                        setStateBulk={setStateBulk}
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                        setGlobalStateBulk={setGlobalStateBulk}
                    />
                );
            })}
        </HeaderContainer>
    );
};

export default Header;
