import useI18n from 'i18n/useI18n';
import React from 'react';
import { chargeSuccess, needsCharge, chargePending } from 'utils/status';
import { BoldText, Text } from 'components/Text';
import { formatMoney } from 'utils/text';
import colors from 'resources/constants/colors';

const Action = ({ item }) => {
  const { translate } = useI18n();

  const getBody = () => {
    if (needsCharge(item)) return <Text>{translate('Not charged yet')}</Text>;
    if (chargePending(item)) return <BoldText>{translate('Charge Pending')}</BoldText>;
    if (chargeSuccess(item)) return <BoldText>{translate('Charged x', {charge: formatMoney((item.link?.charge?.amount ?? 0)/100)})}</BoldText>;
    if (!!item.link?.electedNoCharge) return <Text>{translate(item.link?.electedNoCharge.reason)}</Text>;
    return <BoldText color={colors.cancel}>{translate(item.link?.charge?.statusCode)}</BoldText>;
  };

  return <div style={{ alignItems: 'center', display: 'flex', marginLeft: '5px' }}>{getBody()}</div>;
};

export default Action;
