import React, { useEffect } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';
import colors from "resources/constants/colors";

const CustomSnackBar = styled(Snackbar)`
    .MuiPaper-root {
      ${({alertColor}) => alertColor ? `background-color: ${alertColor};` : ''}; 
    }
`;

const SnacksServiceContext = React.createContext({});
const AUTO_DISMISS = 4000;

export const SnackbarTypes = {
  ERROR: 'error',
  INFO: 'info',
}

export const SnacksServiceProvider = ({ children }) => {

  const [alerts, setAlerts] = React.useState([]);

  const activeAlertIds = alerts.join(',')
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(() => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)), AUTO_DISMISS)
      return () => clearTimeout(timer)
    }
  }, [activeAlertIds])

  const addAlert = (alert) => {
    setAlerts((alerts) => [alert, ...alerts]);
  };

  const getSeverity = (type) => {
    switch(type){
      case SnackbarTypes.ERROR:
        return colors.cancel;
      case SnackbarTypes.INFO:
        return 'blue';
      default:
        return null;
    }
  }

  return (
    <>
      <SnacksServiceContext.Provider
        value={addAlert}
        children={children}
      />
      { alerts.map((alert, i) => <CustomSnackBar key={i} alertColor={getSeverity(alert.type)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={true} message={alert.message} />)}
    </>
  );
};

export const useSnacks = () => React.useContext(SnacksServiceContext);