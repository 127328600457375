import React from 'react';
import styled from 'styled-components';
import colors from 'resources/constants/colors';
import Card from 'containers/Card';
import color from 'resources/constants/colors';

export const BaseButton = styled.button`
  width: 100%;
  padding: 15px 5px;
  border: 1px solid ${({ color }) => (color ? color : colors.accept)};
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:focus {
    outline: 1px solid ${({ color }) => (color ? color : colors.accept)};
  }
`;

export const ColorButton = styled(BaseButton)`
  color: white;
  background-color: ${({ color }) => (color ? color : colors.accept)};
  &:hover {
    background-color: white;
    color: ${({ color }) => (color ? color : colors.accept)};
  }

  
`;

export const TransparentButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ color, disabled }) => (disabled ? colors.disabled : color ? color : colors.accept)};
  &:hover {
    background-color: ${({ color }) => (color ? color : colors.accept)};
    color: white;
  }
`;

export const AltTransparentButton = styled(TransparentButton)`
  border: none;
  &:hover {
    background-color: ${({ backgroundColor, disabled }) => (disabled ? 'inherit' : backgroundColor ? backgroundColor : colors.lightBlue)};
    color: ${({ color, disabled }) => (disabled ? colors.disabled : color ? color : colors.accept)};
  }
  &:focus {
    outline: none;
  }
`;

export const TransparentDarkButton = styled(TransparentButton)`
  color: white;
`;

export const AcceptButton = ({ text, onClick, disabled, type = 'button', style }) => (
  <Card style={style}>
    <ColorButton type={type} color={disabled ? colors.disabled : colors.accept} onClick={disabled ? () => {} : onClick} style={{ disabled: disabled }}>
      {!!text ? text : 'Accept'}
    </ColorButton>
  </Card>
);

export const CancelButton = ({ text, dark = false, onClick, type = 'button' }) => {
  const Button = dark ? TransparentDarkButton : TransparentButton;

  return (
    <Card>
      <Button type={type} color={colors.cancel} onClick={onClick}>
        {!!text ? text : 'Cancel'}
      </Button>
    </Card>
  );
};

export const Link = styled.a`
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
`;

export const AcceptLink = styled(Link)`
  color: ${color.accept};
  font-size: 14px;

  @media( min-width: 768px ){
    font-size: 15px;
  }
`;

export const CancelLink = styled(Link)`
  color: ${color.cancel};
`;