import React from 'react';
import HeaderText from 'components/DynamicTable/cells/Headers/Text';
import Text from 'components/DynamicTable/cells/Fixed/Text';
import RowContainer from 'components/Table/RowContainer';
import { getFormattedName, getFormattedPhone } from 'utils/text';
import ArrowButton from 'components/Buttons/ArrowButton';
import hookAlertTypes from 'alert/types';
import GenerateLink from 'pages/home/pages/Reservations/alert/GenerateLink';
import ViewDetails from '../../cells/ViewDetails';
import CreatingLinkStatus from '../../cells/CreatingLinkStatus';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 3,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Action Item')} />,
      body: ({ globalState, item }) => (
        <ArrowButton
          onClick={() => {
            globalState.config
              .alert({
                type: hookAlertTypes.CUSTOM,
                config: {
                  props: {
                    id: item.id,
                  },
                  body: GenerateLink,
                },
              })
              .catch((e) => {});
          }}
          text={globalState.config.translate('Create Link')}
        />
      ),
    },
    {
      flex: 4,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Date and Time')} />,
      body: ({ globalState, item }) => <Text text={globalState.config.formatDate(item.scheduledTime)} />,
    },
    {
      flex: 4,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Last, First Name')} />,
      body: ({ item }) => <Text text={getFormattedName(item.guest)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <HeaderText style={{ justifyContent: 'center' }} text={globalState.config.translate('#ppl')} />,
      body: ({ item }) => <Text style={{ justifyContent: 'center' }} text={item.partySize} />,
    },
    {
      flex: 3,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Phone')} />,
      body: ({ item }) => <Text text={getFormattedPhone(item.guest)} />,
    },
    {
      flex: 2,
      header: () => <HeaderText text={''} />,
      body: ViewDetails,
    },
    {
      flex: 1,
      header: () => <HeaderText text={''} />,
      body: CreatingLinkStatus,
    },
  ],
};

export default structure;
