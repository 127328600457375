import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import Header from './containers/Header.jsx';
import Row, { Sortable } from './components/Row.jsx';
import { TableProvider } from './TableProvider.jsx';
import { TableContext } from './TableProvider.jsx';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import colors from 'resources/constants/colors.js';
import { Spinner } from "@etiquette-ui/misc";

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background: white;
  height: calc(100vh - 250px);
  position: relative;
`;

const TableContainer = styled.div`
  overflow: scroll;
  margin-top: 45px;
  height: calc(100vh - 250px - 45px);
`;

const DynamicTable = ({ items, className, rowStyle, primaryKey = 'id', onSortEnd, isLoading, loadCallback = ()=>{}, intersectionId }) => {
  const [processed, setProcessedItems] = useState(items);
  const [filters, setFilters] = useState(new Map());
  const { dragable, globalState } = useContext(TableContext);

  useEffect(() => {
    applyAllFilters();
  }, [items, filters]);

  const applyFilter = (key, filter) => {
    setFilters(new Map(filters.set(key, filter)));
  };

  const applyAllFilters = () => {
    let filtered = [...items];
    Array.from(filters.values()).forEach((filter) => {
      filtered = filter(filtered);
    });
    setProcessedItems(filtered);
  };

  const commitMove = (oldIndex, newIndex) => () => {
    setProcessedItems(arrayMove(processed, oldIndex, newIndex));
  };

  const handleSort = async ({ oldIndex, newIndex }) => {
    onSortEnd({ processed, oldIndex, newIndex, globalState, commitMove: commitMove(oldIndex, newIndex) });
  };

  const ref = useRef();

  const handleObserver = (entries) => {
    const [entry] = entries;
    if (!isLoading && entry.isIntersecting) {
      loadCallback();
    }
  };

  const observer = useMemo(
    () => new IntersectionObserver(
      handleObserver, 
      { 
        root: !!intersectionId ? document.querySelector(`#${intersectionId}`) : null, 
        rootMargin: '75px', 
        threshold: 0.5, 
      }
      ),[]);

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, ref.current, items]);

  return (
    <Container className={className} id='dynamic-table-main-container'>
      <Header applyFilter={applyFilter} />
      {dragable ? (
        <SortableWrapper className="body" onSortEnd={handleSort} useDragHandle={true}>
          {processed.map((item, i) => (
            <Sortable
              key={item[primaryKey]}
              index={i}
              count={i}
              item={item}
              applyFilter={applyFilter}
              style={rowStyle}
            />
          ))}
        </SortableWrapper>
      ) : (
        <TableContainer className="body">
          {processed.map((item, i) => (
            <Row
              key={item[primaryKey]}
              count={i}
              item={item}
              applyFilter={applyFilter}
              style={rowStyle}
            />
          ))}
          {<div ref={ref} style={{ height: '5px' }}></div>}
          {isLoading && 
            <div style={{margin: '10px 0'}} className='table-spinner-container'>
              <Spinner 
                style={{
                  '--width': '45px',
                  '--margin': '0 auto',
                  '--bg': 'white',
                  '--color': `${colors.accept}`
                }}
              />
            </div>
          }
        </TableContainer>
      )}
    </Container>
  );
};

const SortableWrapper = SortableContainer(({ children, ...rest }) => {
  return <TableContainer {...rest}>{children}</TableContainer>;
});

const Table = ({ config = {}, structure, dragable, ...rest }) => {
  return (
    <TableProvider tableStructure={structure} tableConfig={config} isDragable={dragable}>
      <DynamicTable {...rest} />
    </TableProvider>
  );
};

export default Table;
