import React from "react";
import colors from "resources/constants/colors";
import useI18n from "i18n/useI18n";
import { Spinner } from "@etiquette-ui/misc";

const HeaderComponent = ({ date, decreaseMonth, increaseMonth }) => {
  const { formatDate } = useI18n();

  return (
    <>
      <button
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        onClick={decreaseMonth}
        aria-label="Previous Month"
      >
        Previous Month
      </button>
      <button
        type="button"
        className="react-datepicker__navigation react-datepicker__navigation--next"
        onClick={increaseMonth}
        aria-label="Next Month"
      >
        Next Month
      </button>
      <div className="react-datepicker__current-month">
        <div className="label">
          {formatDate(date, "MMMM yyyy")}
          <div className="spinner">
            <Spinner
              style={{
                '--width': '15px',
                '--margin': '0 5px',
                '--bg': 'white',
                '--color': `${colors.textMain}`
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * This components has other params
 *  changeYear, changeMonth,prevMonthButtonDisabled, nextMonthButtonDisabled
 */

const Header = ({ date, decreaseMonth, increaseMonth }) => {
  return (
    <HeaderComponent
      date={date}
      decreaseMonth={decreaseMonth}
      increaseMonth={increaseMonth}
    />
  );
};

export default Header;
