import React from 'react';
import colors from 'resources/constants/colors';
import { BoldText, Text } from 'components/Text';
import styled from 'styled-components';

const Notification = ({ title, info, message, action, isRead }) => {
  return (
    <Container active={!isRead}>
      <Title>{title}</Title>
      <Text style={{ fontSize: '0.875rem', wordBreak: 'ellipsis' }}>{info}</Text>
      {!!message && <Text style={{ fontSize: '0.875rem', textOverflow: 'ellipsis' }}>{message}</Text>}
      {!!action && <Action onClick={action.callback}>{action.text}</Action>}
    </Container>
  );
};

const Container = styled.div`
  padding: 8px;
  background-color: ${({ active }) => (active ? colors.lightBlue : 'transparent')};
  width: 100%;
  margin-bottom: 10px;
`;

const Title = styled(BoldText)`
  font-size: 1rem;
  font-weight: 700;
`;

const Action = styled.p`
  color : ${colors.accept};
  cursor: pointer;
`

export default Notification;
