import colors from 'resources/constants/colors';
import styled from 'styled-components';

const RowContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
    column-gap: 5px;
    overflow-y: scroll;
    padding: 0px 15px;
    min-height: 45px;
    border-radius: 8px;
    background-color: ${({count})=>count%2===0 ? `${colors.backgroundLightGrey}` : 'transparent'};
    overflow:  hidden;
`;

export default RowContainer;