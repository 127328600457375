import React from "react";
import { BoldText } from "components/Text";
import useDate from "state/date";
import colors from "resources/constants/colors";
import styled from "styled-components";
import useReservations from "state/reservations";
import useI18n from "i18n/useI18n";
import { DISPLAY_DATE_FORMAT, stringToDate } from "utils/date";
import { Text } from "components/Text";
import { Spinner } from "@etiquette-ui/misc";

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const ShowDate = () => {
  const { translate, formatDate } = useI18n();
  const [date] = useDate();
  const [reservations] = useReservations();

  return (
    <Container>
      <BoldText>{formatDate(stringToDate(date), DISPLAY_DATE_FORMAT)}</BoldText>
      <Text style={{marginLeft: '10px'}}>{reservations.dates[date] ? `(${reservations.dates[date].length} ${translate('reservations')})` : ''}</Text>
      {reservations.isFetching && (
        <Spinner
          style={{
            '--width': '15px',
            '--margin': '0 5px',
            '--bg': 'white',
            '--color': `${colors.textMain}`
          }}
        />
      )}
    </Container>
  );
};

export default ShowDate;
