import styled from 'styled-components';

const RowContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
    column-gap: 5px;
    overflow-y: scroll;
    padding: 5px 0;
    min-height: 45px;
`;

export default RowContainer;