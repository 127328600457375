import DynamicTable from 'components/DynamicTable';
import colors from 'resources/constants/colors';
import styled from 'styled-components';

const Table = styled(DynamicTable)`
  height: 100%;
  width: max-content;
  background-color: transparent;
  background: transparent;
  display: grid;
  grid-template-rows: 45px minmax(0, 1fr);

  position: absolute;
  top: 0px;
  left: 0px;

  padding-left: 15px;
  padding-right: 15px;

  .header {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    & *:first-child {
      border-right: solid 1px ${colors.lighterGrey};
      margin-left: 0px;
    }
  }
  .body {
    margin-top: 0;
    height: 100%;
    padding-right: 0;
    padding-bottom: 6rem;;
    & > div:not(.table-spinner-container) {
      & > *:first-child{
        border-right: solid 1px ${colors.lighterGrey};
      }
    }
  }

  @media( min-width: 768px ){
    width: 100%;
    margin-left: 0px;
    position: relative;
    padding-left: 0px;
    padding-right: 0px;

    .body {
      padding-bottom: 2rem;
    }
  }
`;

export const TableNoDivider = styled(Table)`
  .header {
    & *:first-child {
      border-right: none;
    }
  }
  .body {
    & > div:not(.table-spinner-container) {
      & > *:first-child{
        border-right: none;
      }
    }
  }
`;

export default Table;
