import React from 'react';
import { LangDropdown } from '@etiquette-ui/inputs-dropdowns';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';

const LangSelector = styled(LangDropdown)`
  position: relative;
  order: 2;

  @media (max-width: 768px) {
    margin-right: 0px;
    order: 1;
  }
  span {
    color: white;
  }
  > div:first-child {
    @media (max-width: 1000px) {
      grid-template-columns: 28px;
      width: auto;
      div span,
      div svg {
        display: none;
      }
    }
  }
  > div:not(:first-child) {
    position: absolute;
    top: 28px;
    @media (max-width: 768px) {
      right: 0;
    }
  }
`;

const Lang = () => {
  const { selectedLang, setSelectedLang } = useI18n();

  return (
    <LangSelector
      selectedLang={selectedLang}
      setSelectedLang={setSelectedLang}
      chevronStyles={{
        line: {
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
          stroke: 'white',
        },
      }}
    />
  );
};

export default Lang;
