import React from 'react';
import HeaderText from 'components/DynamicTable/cells/Headers/Text';
import Text from 'components/DynamicTable/cells/Fixed/Text';
import RowContainer from 'components/Table/RowContainer';
import { getFormattedName, getFormattedPhone } from 'utils/text';
import ExtendTime from './cells/ExtendTime';
import ViewDetails from '../../cells/ViewDetails';
import LastUpdatedBy from '../../../../../../../../components/Table/LastUpdatedBy';
import { genWhatsappMessage } from '../../../../../../../../utils/gen_whatsapp_message';
import {
    formatTime,
    getTimeUntilExpiration,
} from '../../../../../../../../utils/time';
import colors from '../../../../../../../../resources/constants/colors';
import { RESERVATIONS } from 'resources/constants/urls';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 5,
            header: ({ globalState }) => (
                <HeaderText
                    text={globalState.config.translate('Time to expiration')}
                />
            ),
            body: ExtendTime,
        },
        {
            flex: 3,
            header: ({ globalState }) => (
                <HeaderText
                    text={globalState.config.translate('Date and Time')}
                />
            ),
            body: ({ globalState, item }) => (
                <Text
                    text={globalState.config.formatDate(item.scheduledTime)}
                />
            ),
        },
        {
            flex: 3,
            header: ({ globalState }) => (
                <HeaderText
                    text={globalState.config.translate('Last, First Name')}
                />
            ),
            body: ({ item }) => <Text text={getFormattedName(item.guest)} />,
        },
        {
            flex: 2,
            header: ({ globalState }) => (
                <HeaderText
                    style={{ justifyContent: 'center' }}
                    text={globalState.config.translate('#ppl')}
                />
            ),
            body: ({ item }) => (
                <Text
                    style={{ justifyContent: 'center' }}
                    text={item.partySize}
                />
            ),
        },
        {
            flex: 3,
            header: ({ globalState }) => (
                <HeaderText
                    style={{ justifyContent: 'center' }}
                    text={globalState.config.translate('Phone')}
                />
            ),
            body: ({ item, globalState, step }) => (
                <Text
                    style={{
                        justifyContent: 'center',
                        color: colors.accept,
                        cursor: 'pointer',
                    }}
                    text={getFormattedPhone(item.guest)}
                    onClick={() =>
                        genWhatsappMessage(
                            getFormattedPhone(item.guest),
                            globalState.config.translate('Whats app message', {
                                time: `${formatTime(
                                    getTimeUntilExpiration(item)
                                )} ${globalState.config.translateSeconds(
                                    getTimeUntilExpiration(item)
                                )}`,
                                linkURL: `${RESERVATIONS}/?uid=${item.link.code}`
                            })
                        )
                    }
                />
            ),
        },
        {
            flex: 3,
            header: ({ globalState }) => (
                <HeaderText text={globalState.config.translate('Created by')} />
            ),
            body: ({ item }) => (
                <LastUpdatedBy item={item} field={'createdBy'} />
            ),
        },
        {
            flex: 3,
            header: ({ globalState }) => (
                <HeaderText
                    text={globalState.config.translate('Last Change')}
                />
            ),
            body: ({ item }) => <LastUpdatedBy item={item} />,
        },
        {
            flex: 2,
            header: () => <HeaderText text={''} />,
            body: ViewDetails,
        },
    ],
};

export default structure;
