import React, { useContext, useState } from 'react';
import { addValueToObject } from '../objectUtils.js';
import { TableContext } from '../TableProvider.jsx';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import BaseRow from '../cells/BaseRow.js';

const Row = React.forwardRef(({ item, style = {}, className, count }, ref) => {
    const { structure, dragable, gridColumns, globalState, setGlobalState, setGlobalStateBulk } = useContext(TableContext);
    const [state, replaceState] = useState({});

    const setState = (key, value) => {
        const data = { ...state };
        addValueToObject(data, key, value);
        replaceState(data);
    };

    const setStateBulk = (items) => {
        const data = { ...state };
        for (const key in items) {
            addValueToObject(data, key, items[key]);
        }
        replaceState(data);
    };

    const RowContainer = structure.row ? structure.row : BaseRow;

    return (
        <RowContainer columns={gridColumns} dragable={dragable} style={style} className={className} item={item} state={state} globalState={globalState} count={count} ref={ref}>
            {dragable && <DragHandle />}
            {structure.columns.map((col, i) => {
                const Component = col.body;
                return (
                    <Component
                        key={i}
                        count={count}
                        item={item}
                        state={state}
                        setState={setState}
                        replaceState={replaceState}
                        setStateBulk={setStateBulk}
                        globalState={globalState}
                        setGlobalState={setGlobalState}
                        setGlobalStateBulk={setGlobalStateBulk}
                    />
                );
            })}
        </RowContainer>
    );
});

const DragHandle = sortableHandle(() => <div style={{ cursor: 'pointer', fontSize: '26px', fontWeight: '900', margin: 'auto', userSelect: 'none' }}>::</div>);

export const Sortable = SortableElement((props) => <Row {...props} />);

export default Row;
