import React from "react";
import styled from "styled-components";
import colors from "resources/constants/colors";
import AmountIcon from "resources/img/amount-icon.svg";
import StatusIcon from 'resources/img/pending-icon.svg'
import DescriptionIcon from "resources/img/description-icon.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => (margin ? margin : "10px 0")};
`;

export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  grid-column-gap: 20px;
  justify-items: start;
  padding: 10px;
  background-color: ${colors.backgroundLightGrey};
  border-radius: 8px;
  align-items: center;
`;

export const Icon = styled.img`
  width: 20px;
  height: auto;
`;

export const InputLabel = styled.h2`
  color: ${colors.textMain};
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 4px;
  text-align: left;
`;

export const InfoText = styled.p`
  color: ${colors.textMain};
  font-weight: 500;
  font-size: 1.2rem;
  text-align: left;
  opacity: 0.5;
  margin: ${({ margin }) => (margin ? margin : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
`;

const InputStyles = `
    border: none;
    background-color: transparent;
    color: ${colors.textMain};
    font-weight: 500;
    font-size: 0.9rem;
    width: 100%;
    &:focus {
        outline: unset;
    }
    ::placeholder {
        opacity: 0.3;
    }  
`;

const StyledInput = styled.input`
  ${InputStyles}
`;

export const BaseInput = ({ icon, title, required = false, children }) => {

  return (
    <Container>
      <InputLabel>{title}</InputLabel>
      <InputContainer>
        <Icon src={icon} />
        {children}
      </InputContainer>
      {required && (
        <InfoText margin="3px 0 0 0" padding="0 0 0 16px">
          "REQUIRED"
        </InfoText>
      )}
    </Container>
  );
};

export const DefaultInput = ({
  type,
  onChange,
  value,
  placeholder,
  ...rest
}) => {
  return (
    <BaseInput {...rest}>
      <StyledInput
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </BaseInput>
  );
};

export const MoneyInput = ({ onChange, title, required, value, placeholder }) => (
  <DefaultInput
    icon={AmountIcon}
    onChange={onChange}
    title={title}
    placeholder={placeholder}
    required={required}
    value={value}
  />
);

export const ReasonInput = ({ onChange, title, required, value, placeholder }) => (
  <DefaultInput
    icon={StatusIcon}
    onChange={onChange}
    title={title}
    placeholder={placeholder}
    required={required}
    value={value}
  />
);

export const DescriptionInput = ({ onChange, title, required, value, placeholder }) => (
  <DefaultInput
    icon={DescriptionIcon}
    onChange={onChange}
    title={title}
    placeholder={placeholder}
    required={required}
    value={value}
  />
);
