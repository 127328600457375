import React, { useState } from 'react';
import styled from 'styled-components';
import TimeInput from 'components/Inputs/TimeInput';
import useI8n from 'i18n/useI18n';
import { ColorButton, AltTransparentButton } from 'components/Buttons';
import colors from 'resources/constants/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const Input = styled(TimeInput)`
  background-color: white;
  margin-right: 10px;
  max-width: 150px;
  padding: 5px 10px;
`;

const AcceptButton = styled(ColorButton)`
  padding: 8px 5px;
  max-width: 100px;
  margin-right: 10px;
`;

const CancelButton = styled(AltTransparentButton)`
  padding: 10px 5px;
  max-width: 100px;
  color: ${colors.cancel};
  &:hover {
    color: ${colors.cancel};
    background-color: ${colors.lightRed};
  }
`;

const TimeUpdateStep = ({ item, previous, currentStep, updateLink }) => {
  const [expirationTime, setExpirationTime] = useState(30);
  const [expirationInterval, setExpirationInterval] = useState(0);
  const { translate, expirations } = useI8n();
  

  return currentStep === 1 ? (
    <Container>
      <Input
        onChange={(e) => setExpirationTime(e.target.value)}
        value={expirationTime}
        onChangeSelector={(e) => setExpirationInterval(e.target.value)}
        selectorValue={expirationInterval}
        selectorOptions={expirations}
        mini={true}
      />
      <AcceptButton type="submit" onClick={()=>updateLink(expirationTime, expirations[expirationInterval].multiplier)}>{translate('Update')}</AcceptButton>
      <CancelButton onClick={previous}>{translate('Cancel')}</CancelButton>
    </Container>
  ) : null;
};

export default TimeUpdateStep;
