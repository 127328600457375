import styled from 'styled-components';
import colors from 'resources/constants/colors';
import Card from 'containers/Card';

export const SelectorContainer = styled.div`
  height: 56px;
  order: 1;
  display: flex;
  align-items: center;
  background-color: ${colors.backgroundLightGrey};
  border-radius: 10px;
  padding: 10px;
  position: relative;
  margin-right: 10px;

  @media( min-width: 768px ) and ( max-width: 1024px ){
    margin-left: 15px!important;
  }
`;

export const ValueContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

export const Label = styled.label`
  font-weight: 500;
  color: ${colors.accept};
  pointer-events: none;
  font-size: 12px;
  margin-bottom: 5px;

  @media( min-width: 768px ){
    font-size: 0.75rem;
  }
`;

export const ChevronContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 20px;
  right: -5px;
  display: flex;
  align-items: center;
`;

export const Chevron = styled.img`
  position: relative;
  height: 20px;
  width: 20px;
`;

export const SelectionsContainer = styled(Card)`
  position: absolute;
  top: 56px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 8px;
  z-index: 5;
  min-height: ${({minHeight})=>minHeight}px;
  transition-duration: 150ms;
  transition-timing-function: ease-in;
  &.expanded {
    min-height: 390px;
  }
`;