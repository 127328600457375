import { Text } from 'components/Text';
import React from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  border-radius: 0px 0px 24px 24px;
  padding: 0 36px;
  display: flex;
  align-items: center;
`;

const Title = styled(Text)`
  font-style: italic;
  font-weight: 700;
  color: ${colors.cancel};
`;

const Banner = ({ children }) => {
  return (
    <Container>
      <Title>{children}</Title>
    </Container>
  );
};

export default Banner;
