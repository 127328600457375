import ArrowButton from 'components/Buttons/ArrowButton';
import useI18n from 'i18n/useI18n';
import React from 'react';
import { chargeSuccess, needsCharge, chargePending } from 'utils/status';
import hookAlertTypes from 'alert/types';
import { getAlertBody } from 'pages/home/pages/Reservations/alert/utils';
import colors from 'resources/constants/colors';
import { BoldText } from 'components/Text';

const Action = ({ item, globalState }) => {
  const { translate } = useI18n();

  const getBody = () => {
    if (needsCharge(item))
      return (
        <ArrowButton
          onClick={() => {
            globalState.config
              .alert({
                type: hookAlertTypes.CUSTOM,
                config: {
                  props: {
                    id: item.id,
                  },
                  body: getAlertBody(item),
                },
              })
              .catch((e) => {});
          }}
          text={globalState.config.translate('Add Charge')}
        />
      );
    if (chargePending(item)) return <BoldText>{translate('Charge Pending')}</BoldText>;
    if (chargeSuccess(item)) return <BoldText color={colors.success}>{translate('Successful Charge')}</BoldText>;
    if (!!item.link?.electedNoCharge) return <BoldText>{translate('Manager Approved No Charge')}</BoldText>;
    return <BoldText>{translate('Charge Rejected')}</BoldText>;
  };

  return <div style={{ alignItems: 'center', display: 'flex'}}>{getBody()}</div>;
};

export default Action;
