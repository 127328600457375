import React, { useEffect } from 'react'
import colors from "resources/constants/colors";
import useReservations from 'state/reservations';
import { Spinner } from "@etiquette-ui/misc";

const CreatingLinkStatus = ( {item, spinnerForeground, spinnerBackground} ) => {
  const [reservations] = useReservations();

  return reservations.fetchingData.includes(item.id) ? (
    <Spinner 
      style={{
        '--width': '15px',
        '--margin': '15px 5px 0px 5px',
        '--bg': 'transparent',
        '--color': `${colors.accept}`
      }}
    />
  ) : null;
}


export default CreatingLinkStatus;
