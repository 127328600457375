import React, { createContext, useEffect, useState } from 'react';
import { addValueToObject } from './objectUtils.js';
import { getGridColumnsString } from './utils.js';

export const TableContext = createContext();

export const TableProvider = ({ tableConfig, tableStructure, isDragable, children }) => {
    const [structure, setStructure] = useState(tableStructure);
    const [gridColumns, setGridColumns] = useState(getGridColumnsString(structure, isDragable));
    const [dragable, setDragable] = useState(isDragable);
    const [globalState, replaceGlobalState] = useState({config: tableConfig});

    const setGlobalState = (key, value) => {
        const data = {...globalState};
        addValueToObject(data, key, value);
        replaceGlobalState(data);
    }

    const setGlobalStateBulk = (items) => {
        const data = {...globalState};
        for (const key in items) {
            addValueToObject(data, key, items[key]);
        }
        replaceGlobalState(data);
    }

    useEffect(()=>{
        setGlobalState('config', tableConfig);
    },[tableConfig]);

    useEffect(() => {
        setStructure(tableStructure);
    }, [tableStructure]);

    useEffect(() => {
        setDragable(isDragable);
    }, [isDragable]);

    useEffect(() => {
        setGridColumns(getGridColumnsString(structure, dragable));
    }, [structure, dragable]);

    return <TableContext.Provider value={{structure, dragable, gridColumns, globalState, setGlobalState, setGlobalStateBulk}}>{children}</TableContext.Provider>
}