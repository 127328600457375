import { Text } from 'components/Text';
import useI18n from 'i18n/useI18n';
import React from 'react';
import colors from 'resources/constants/colors';
import styled from 'styled-components';
import { DATE_RANGE_TYPES } from 'resources/constants/selectors';
import usePendingCharges from 'state/pendingCharges';

const Container = styled.div`
  display: flex;
  align-self: center;
  margin: 0 10px;
  border-bottom: 1px solid ${colors.backgroundLightGrey};
  height: 100%;
  align-items: center;
`;

const Type = styled.div`
  background-color: ${({ selected }) => (selected ? colors.accept : colors.backgroundGrey)};
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  margin-right: 5px;
  p {
    color: ${({ selected }) => (selected ? 'white' : colors.textMain)};
  }
`;

const Name = styled(Text)`
  font-size: 0.875rem;
  user-select: none;
`;

const TypeSelector = () => {
  const { translate } = useI18n();
  const [pending, dispatcher] = usePendingCharges();

  return (
    <Container>
      {Object.values(DATE_RANGE_TYPES).map((opt) => (
        <Type key={opt} selected={opt === pending.dateRangeType} onClick={()=>dispatcher.changeDateType(opt)}>
          <Name>{translate(opt)}</Name>
        </Type>
      ))}
    </Container>
  );
};

export default TypeSelector;
