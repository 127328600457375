import React, { useContext, useEffect } from 'react';
import LinkSVG from 'resources/img/link-icon-alt.svg';
import ClockImg from 'resources/img/clock-icon.svg';
import { isExpired } from 'utils/status';
import CommonInfoElement from 'pages/home/pages/Reservations/alert/components/CommonInfo/CommonInfoElement';
import { RESERVATIONS } from 'resources/constants/urls';
import copy from 'copy-to-clipboard';
import { useSnacks } from 'snackbar';
import { AltTransparentButton } from 'components/Buttons';
import colors from 'resources/constants/colors';
import useI8n from 'i18n/useI18n';
import { ClockContext } from 'providers/clock';
import { useState } from 'react';
import { getTimeUntilExpiration, formatTime } from 'utils/time';
import { Text } from 'components/Text';
import styled from 'styled-components';
import { useCopyAlert } from 'providers/copy';

const TimeDisplayContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TimeText = styled(Text)`
  font-size: 0.875rem;
  margin-right: 5px;
`;

const TimerDisplay = ({ expired, time }) => {
  const { translate, translateSeconds } = useI8n();

  return (
    <TimeDisplayContainer>
      {!expired && <TimeText color={colors.cancel}>{translate('Expires in')}</TimeText>}
      {<TimeText color={colors.cancel} style={{fontWeight: '600'}}>{expired ? translate('Expired') : `${formatTime(time)} ${translateSeconds(time)}`}</TimeText>}
    </TimeDisplayContainer>
  );
};

const TimeInfo = ({ reservation, onClose }) => {
  const copyAlert = useCopyAlert();
  const { translate } = useI8n();
  const [subscribe, unsusbcribe] = useContext(ClockContext);
  const [expirationTimer, setExpirationTimer] = useState(0);

  useEffect(() => {
    subscribe(tick);
    return () => {
      unsusbcribe(tick);
    };
  }, []);

  useEffect(()=>{
    if (copy(`${RESERVATIONS}/?uid=${reservation.link.code}`)) {
      copyAlert('The link has been copied.');
    }
  }, [reservation.link?.code]);

  const tick = () => {
    setExpirationTimer(getTimeUntilExpiration(reservation));
  };

  const copyToClipboard = () => {
    if (copy(`${RESERVATIONS}/?uid=${reservation.link.code}`)) {
      copyAlert('The link has been copied.');
      onClose();
    }
  };

  return (
    <>
      <CommonInfoElement
        source={ClockImg}
        data={<TimerDisplay expired={isExpired(reservation)} time={expirationTimer} />}
        textColor={isExpired(reservation) ? colors.cancel : colors.textMain}
      />
      <CommonInfoElement
        source={LinkSVG}
        data={`${RESERVATIONS}/?uid=${reservation.link.code}`}
        action={
          <AltTransparentButton onClick={copyToClipboard} style={{ maxWidth: '100%', padding: '10px 0' }}>
            {translate('Copy')}
          </AltTransparentButton>
        }
      />
    </>
  );
};

export default TimeInfo;
