import React from 'react';
import HeaderText from 'components/DynamicTable/cells/Headers/Text';
import Text from 'components/DynamicTable/cells/Fixed/Text';
import RowContainer from 'components/Table/RowContainer';
import { getFormattedName } from 'utils/text';
import ViewDetails from '../../cells/ViewDetails';
import ResetTime from './cells/ResetTime';
import LastUpdatedBy from '../../../../../../../../components/Table/LastUpdatedBy';
import ExpiredTimerComponent from './cells/CancelledTimerComponent';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 6,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Reset time or Cancel')} />,
      body: ResetTime,
    },
    {
      flex: 3,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Expired Ago')} />,
      body: ({ item }) => <ExpiredTimerComponent reservation={item} />,
    },
    {
      flex: 3,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Last, First Name')} />,
      body: ({ item }) => <Text text={getFormattedName(item.guest)} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => <HeaderText style={{ justifyContent: 'center' }} text={globalState.config.translate('#ppl')} />,
      body: ({ item }) => <Text style={{ justifyContent: 'center' }} text={item.partySize} />,
    },
    {
      flex: 3,
      header: ({ globalState }) => <HeaderText text={globalState.config.translate('Date and Time')} />,
      body: ({ globalState, item }) => <Text text={globalState.config.formatDate(item.scheduledTime)} />,
    },
    {
      flex: 3,
      header: ({globalState}) => <HeaderText text={globalState.config.translate('Created by')} />,
      body: ({ item }) => <LastUpdatedBy item={item} field={'createdBy'} />,
    },
    {
      flex: 3,
      header: ({globalState}) => <HeaderText text={globalState.config.translate('Last Change')} />,
      body: ({ item }) => <LastUpdatedBy item={item} />,
    },
    {
      flex: 2,
      header: () => <HeaderText text={''} />,
      body: ViewDetails,
    },
  ],
};

export default structure;
