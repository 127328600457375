import React from 'react';
import styled from 'styled-components';
import Icon from '@etiquette-ui/icons';
import { Spinner } from "@etiquette-ui/misc";
import colors from "resources/constants/colors";

export const IconButtonText = styled.p`
  font-weight: 700;
  color: white;
  font-size: 14px;
  margin: 0;
  user-select: none;
  max-width: 75%;

  @media (max-width: 768px) {
    font-size: 13px;
    font-weight: 600;
  }
`;

export const IconButtonContainer = styled.div`
  display: flex;
  margin-left: 5px;
  ${({ active }) => active ? 'cursor: pointer;' : ''}
  align-items: center;

  &.onMenu {
    
    svg {
      filter: brightness(0%) invert(1);
    }

    @media (max-width: 1000px) {
      ${IconButtonText}{
        display: none;
      }

      svg {
        filter: brightness(100%) invert(0);
      }
    }
  }
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  max-width: 20px;
  flex: 0 0 20px;
`;

export const IconButtonIcon = styled.img`
  height: 18px;
`;

const IconButton = ({ onClick, icon, text, className, color = colors.accept, isMenuItem = false, loading = false, leading=true }) => {
  return (
    <IconButtonContainer 
      onClick={loading ? ()=>{} : onClick} 
      className={isMenuItem ? className+' onMenu' : className} 
      active={!loading}
    >
      {!leading && <IconButtonText style={{marginRight: '10px'}}>{text}</IconButtonText>}
      {loading && 
        <Spinner 
          style={{
            '--width': '15px',
            '--margin': '0',
            '--bg': 'white',
            '--color': `${colors.accept}`
          }} 
        />
      }
      {!loading && (
        <IconContainer>
          <Icon icon={icon} styles={{ line: {fill: 'none', strokeWidth:'2.5px', stroke: color} }}/>
        </IconContainer>
      )}
      {leading && <IconButtonText style={{marginLeft: '10px'}}>{text}</IconButtonText>}
    </IconButtonContainer>
  );
};

export default IconButton;
