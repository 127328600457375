export const statusColors = (actionRequired) => actionRequired ? { background: '#E84961', foreground: 'white', border: 'transparent' } : { background: 'white', foreground: 'black', border: 'black' };

const colors = {
  base: '#364456',
  textMain: '#0F191B',
  accept: '#388CFF',
  acceptRGB: '56, 140, 255',
  cancel: '#E84961',
  disabled: '#5F5F5F',
  lightGrey: '#033269',
  lighterGrey: '#0332693D',
  backgroundLightGrey: '#EDEDED',
  backgroundGrey: '#D3D4D6',
  backgroundDarkBlue: '#364456',
  white: '#FFF',
  backgroundLight: '#FFF',
  backgroundDark: '#364456',
  lightBlue: '#83E2FF4C',
  lightRed: '#E849614C',
  positive: '#90DE96',
  success: '#00D510',
  backgroundGreen: '#EDFFEB',
  backgroundRed: '#FFF2F2',
};

export default colors;